import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    filenameConstraint: Object,
    url: String
  };

  removeRow() {
    let element = this.element;

    $(element).parents('.remove-row').fadeOut(100, function () {
      $(this).empty();
      return false;
    });
  }

  validate(event) {
    const fileInput = event.target;
    const fileName = fileInput.value;
    const filenameConstraint = this.filenameConstraintValue;
    const errors = []

    if(this.hasFilenameConstraintValue) {
      const regex = new RegExp(filenameConstraint.validation);
      if (!regex.test(fileName)) {
        const errorMessage = filenameConstraint.message;
        errors.push(errorMessage);
      }
    }

    const form = fileInput.closest('form');
    const fileErrorField = form.querySelector('.file-error');
    const submitButton = form.querySelector('#file_submit');

    if (errors.length > 0) {
      fileErrorField.textContent = errors.join(', ');
      fileErrorField.classList.add('error');
      submitButton.disabled = true;
    } else {
      fileErrorField.textContent = '';
      fileErrorField.classList.remove('error');
      submitButton.disabled = false;
    }
  }

  updateForm(event) {
    showLoadingSpinner();
    const form = $(event.target.closest('form'));

    $.ajax({
      type: 'get',
      url: this.urlValue,
      dataType: 'script',
      data: form.serialize()
    });
  }

}
