import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal"
export default class extends Controller {
  open(event) {
    event.preventDefault()
    const modalId = event.currentTarget.dataset.modalId
    const modal = document.querySelector(`#${modalId}`)

    $(modal).modal('show')
  }
}
