import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="trial-licenses"
export default class extends Controller {

  static targets = ["submit", "checkbox"]

  connect() {
    const recaptchaElement = document.querySelector(".g-recaptcha");
    this.recaptchaCheck = !recaptchaElement;

    this.toggleSubmitButton();

    window.addEventListener("recaptcha:success", this.updateRecaptchaCheck.bind(this));
  }

  disconnect() {
    window.removeEventListener("recaptcha:success", this.updateRecaptchaCheck.bind(this));

    super.disconnect();
  }

  updateRecaptchaCheck() {
    this.recaptchaCheck = true;

    this.toggleSubmitButton();
  }

  toggleSubmitButton() {
    const allChecked = this.checkboxTargets.every(checkbox => checkbox.checked) && this.recaptchaCheck
    this.submitTarget.disabled = !allChecked
  }

  check() {
    this.toggleSubmitButton()
  }
}
