import {Controller} from "@hotwired/stimulus"
import moment from "moment";
import "bootstrap-datepicker"

export default class extends Controller {
  static values = {
    refreshUrl: String,
  };

  static targets = [
    "dateOfOrder",
    "startDate",
    "endDate",
    "supportDuration"
  ];

  connect() {
    const updateEndDate = this.hasSupportDurationTarget

    $(this.dateOfOrderTarget).datepicker().on('change', this.refreshFormData.bind(this));
    $(this.endDateTarget).datepicker().on('change', this.refreshFormData.bind(this));

    $(this.startDateTarget).datepicker().on('change', (event) => {
      if (updateEndDate) {
        this.updateEndDate();
      }
      this.refreshFormData(event);
    });

    $(this.supportDurationTarget).on('change', (event) => {
      if (updateEndDate) {
        this.updateEndDate();
      }
      this.refreshFormData(event);
    });
  }

  disconnect() {
    $(this.dateOfOrderTarget).datepicker().off('change', this.refreshFormData.bind(this));
    $(this.endDateTarget).datepicker().off('change', this.refreshFormData.bind(this));

    $(this.startDateTarget).datepicker().off('change');

    $(this.supportDurationTarget).off('change');
  }

  updateEndDate() {
    const startDateValue = this.startDateTarget.value;
    const supportDurationValue = this.supportDurationTarget.value;

    if (startDateValue && supportDurationValue) {
      const startDate = moment(startDateValue, "DD.MM.YYYY");

      if (startDate.isValid()) {
        const totalDays = this.calculateDays(supportDurationValue);
        const endDate = startDate.add(totalDays, 'days');
        this.endDateTarget.value = endDate.format("DD.MM.YYYY");
      }
    }
  }

  calculateDays(duration) {
    const match = duration.match(/(\d+)\s*(months|years)/i);
    if (!match) return 0;

    const value = parseInt(match[1], 10);
    const unit = match[2].toLowerCase();

    if (unit === "months") {
      return value * 30;  // Convert months to days
    } else if (unit === "years") {
      return value * 365; // Convert years to days
    }

    return 0;
  }

  refreshFormData(event) {
    const element = $(event.target);
    const formElement = element.closest('form');
    const refreshUrl = formElement.data('refreshUrlValue')


    $.ajax({
      type: 'GET',
      url: refreshUrl,
      data: formElement.serialize(),
      dataType: 'script'
    });
  }

}
