import { Controller } from '@hotwired/stimulus'

// Connects to data-controller='modal-visibility'
export default class extends Controller {
  connect() {
  }

  hide(event) {
    const element = $(event.target);
    const hideModal = $('#' + element.data('hideModal'));

    hideModal.modal('hide');
  }

  show(event) {
    const element = $(event.target);
    const showModal = $('#' + element.data('showModal'));

    showModal.modal('show');
  }
}
