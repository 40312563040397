// require ../uncategorized/wizard.js

window.init = function() {

    console.log("init accessed!")

    checkUserAgreed();
    initCookiesEu();
    enableBootstrapTooltips();
    // enable_fixed_table_headers();
    // initScrollHint();

    // $('.datetimepicker').datetimepicker({
    //     icons: {
    //         time: "fa fa-clock-o",
    //         date: "fa fa-calendar",
    //         up: "fa fa-chevron-up",
    //         down: "fa fa-chevron-down",
    //         previous: 'fa fa-chevron-left',
    //         next: 'fa fa-chevron-right',
    //         today: 'fa fa-screenshot',
    //         clear: 'fa fa-trash',
    //         close: 'fa fa-remove'
    //     }
    // });

    // $('.datepicker').datetimepicker({
    //     icons: {
    //         time: "fa fa-clock-o",
    //         date: "fa fa-calendar",
    //         up: "fa fa-chevron-up",
    //         down: "fa fa-chevron-down",
    //         previous: 'fa fa-chevron-left',
    //         next: 'fa fa-chevron-right',
    //         today: 'fa fa-screenshot',
    //         clear: 'fa fa-trash text-primary',
    //         close: 'fa fa-remove'
    //     },
    //     format: 'DD/MM/YYYY',
    //     showClear: true
    // });

    // $('.bootstrap-switch').each(function () {
    //     $this = $(this);
    //     data_on_label = $this.data('on-label') || '';
    //     data_off_label = $this.data('off-label') || '';
    //
    //     $this.bootstrapSwitch({
    //         onText: data_on_label,
    //         offText: data_off_label
    //     });
    // });

    // $('.pagination a').click(function(event) {
    //     event.preventDefault();
    //     $.ajax({type: 'GET',
    //         url:  $(this).attr('href'),
    //         dataType: 'script'
    //     });
    //     return false;
    // });
    //
    // $(function () {
    //     $('[data-toggle="tooltip"]').tooltip()
    // });
    //
    // $("body").on("click", ".delete-row-button", function() {
    //     $($(this).closest(".license_row")[0]).remove();
    // });
    $('.collapse').on('shown.bs.collapse', function(){
        $("table.floatThead-table").trigger('reflow');
    }).on('hidden.bs.collapse', function(){
        $("table.floatThead-table").trigger('reflow');
    });
}

window.showLoadingSpinner = function(){
    $(".loading_overlay").show();
}

window.hideLoadingSpinner = function(){
    $(".loading_overlay").hide();
}

window.enableBootstrapTooltips = function() {
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    popoverTriggerList.map(function (popoverTriggerEl) {
        return new bootstrap.Popover(popoverTriggerEl)
    })
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
    })
}

window.showFlash = function(type, message, wait_for){
    // for details look: http://bootstrap-notify.remabledesigns.com/
    type = type === 'notice' ? 'info' : type;
    if (!wait_for) wait_for = 4000;

    $.notify({
        message: message
    },{
        type: type,
        delay: wait_for,
        allow_dismiss: true,
        placement: {
            from: 'top',
            align: 'left'
        },
        z_index: 9996,
        offset: { x: 20, y: 55 },
        template: '<div class="border-0 alert-dismissible alert alert-{0} fade show text-white col-auto" role="alert">' +
            '<span class="alert-icon">{1}</span> ' +
            '<span class="alert-text text-break">{2}</span>' +
            '<button type="button" class="btn-close fs-4 d-flex align-items-center" data-bs-dismiss="alert" aria-label="Close">'+
            '<span aria-hidden="true">&times;</span>'+
            '</button>'+
            '</div>'
    });
}

window.capitalize = function(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

window.autofillName = function(){
    $('[data-autofillname]').on('blur', function() {
        var email = $(this).val();
        if (email.length > 0) {
            var fullName = email.split('@')[0].split('.');
            var userName = capitalize(fullName[0]);
            if (fullName.length > 1) {
                userName += ' ' + capitalize(fullName[ fullName.length-1 ]);
            }
            var userNameId = $(this).data('autofillname');
            if (userNameId && $(userNameId) && $(userNameId).val().length == 0) {
                $(userNameId).val(userName);
            }
        }
    });
}

$('#search-bar').on('keypress', e => {
    if (e.keyCode === 13) {
        return false;
    }
});

function enable_fixed_table_headers() {
  let width = $(window).width();
  // todo Fixed header not working when screen is sized for mobile (menu changed)
  if( width > 992 ) {
    $("table.floatThead").floatThead({
      responsiveContainer: function($table){
        return $table.closest('.table-responsive');
      }
    });
    setTimeout(function () {
      $("table.floatThead").trigger("reflow");
    }, 400);
  }
}

window.checkUserAgreed = function() {
    if ($('body').data('user-agreement-pending')) {
        $.ajax({
            type: 'GET',
            url: '/terms_and_conditions/pending_user_agreements.js',
            success: function(data) {
                eval(data);
            }
        });
    }
}

window.initCookiesEu = function() {
    windowIsTurbolinked = false;
    cookiesEu.init();
}

window.submitSearch = function (search_queries, searchable_fields, search_url, extra_params){
    // search_queries contains the friendly field names to be searched
    // searchable_fields contains the actual search params required by ransack
    // search_queries[i] must refer to the same field as searchable_fields[i]
    if(search_queries.length !== searchable_fields.length){
        // search_queries.length MUST BE equal to searchable_fields.length
        return;
    }

    let search_query = search.value;
    let search_params = {};
    let array_search_query = [];

    if(search_query.includes(",")){
        array_search_query = search_query.split(",");
        for(let n = 0; n < array_search_query.length; n++){
            if(array_search_query[n].includes(":")){

                for(let i = 0; i < searchable_fields.length; i++){
                    let regex = new RegExp(search_queries[i]+ ":\\s*([a-z0-9]\\s*)*", 'gi');
                    let result = array_search_query[n].match(regex);
                    if(result != null){
                        search_params[searchable_fields[i] + '_cont'] = result[0].split(":")[1].trim();
                    }
                }
            }
            else {
                let general_query;
                general_query = searchable_fields[0];
                for(let i = 1; i < searchable_fields.length; i++){
                    general_query += '_or_' + searchable_fields[i]
                }
                general_query += '_cont';
                search_params[general_query] = array_search_query[n].trim();
            }
        }
    }
    else {
        if(search_query.includes(":")){

            for(let i = 0; i < searchable_fields.length; i++){
                let regex = new RegExp(search_queries[i]+ ":\\s*([a-z0-9\\s]*)*", 'gi');
                let result = search_query.match(regex);
                if(result != null){
                    search_params[searchable_fields[i] + '_cont'] = result[0].split(":")[1].trim();
                }
            }
        }
        else {
            let general_query;
            general_query = searchable_fields[0];
            for(let i = 1; i < searchable_fields.length; i++){
                general_query += '_or_' + searchable_fields[i]
            }
            general_query += '_cont';
            search_params[general_query] = search_query;
        }
    }

    let params = {q: search_params};
    if(typeof extra_params != "undefined"){
        extra_params = JSON.parse(extra_params);
        params = Object.assign({q: search_params}, extra_params);
    }
    $.ajax({
      method: 'GET',
      url:  search_url,
      data: params,
      dataType: 'script'
    });

}

window.delay = function(callback, ms) {
    let timer = 0;
    return function() {
        let context = this, args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            callback.apply(context, args);
        }, ms || 0);
    };
}

window.month_difference = function(end_date, start_date) {
    return (end_date.getMonth()+1) - start_date.getMonth() + (12 * (end_date.getFullYear() - start_date.getFullYear()));
}

window.readDate = function(dateString) {
    let dateParts = dateString.split(" ");
    return new Date(dateParts[0]+'/01/'+dateParts[1]);
}

function initScrollHint() {
  new ScrollHint('.table-responsive', {
    suggestiveShadow: true,
    remainingTime: 0
  });
}

window.responsiveRecaptcha = function(){

    $(function(){
        function rescaleCaptcha(){
            let width = $('.g-recaptcha').parent().width();
            let scale;
            if (width < 302) {
                scale = width / 302;
            } else{
                scale = 1.0;
            }

            $('.g-recaptcha').css('transform', 'scale(' + scale + ')');
            $('.g-recaptcha').css('-webkit-transform', 'scale(' + scale + ')');
            $('.g-recaptcha').css('transform-origin', '0 0');
            $('.g-recaptcha').css('-webkit-transform-origin', '0 0');
        }

        rescaleCaptcha();
        $( window ).resize(function() { rescaleCaptcha(); });

    });
};

window.hideModals = function () {
    const $modals =  document.querySelectorAll('.modal')
    $modals.forEach(modal => {
        let currentModal = bootstrap.Modal.getInstance(modal)
        if (currentModal) currentModal.hide()
    })
}

window.validateForm = function (form) {
    const isValid = form[0].checkValidity();
    form.addClass('was-validated');
    return isValid;
}

window.validateVisibleForm = function (form) {
    const visibleInputs = form.find('input:visible, select:visible');

    let isValid = true;

    visibleInputs.each(function() {
        if (!$(this)[0].checkValidity()) {
            isValid = false;
            return false;
        }
    });

    form.addClass('was-validated');
    return isValid;
}

window.reflowFloatThead = function() {
    $('.fixed-table-header').each(function() {
        $(this).trigger("reflow");
    });
}

window.recaptchaResponse = function() {
    const event = new Event("recaptcha:success");
    window.dispatchEvent(event);
}
